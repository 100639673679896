<template>
  <v-card class="popup">
    <v-card-text>
      <div>
        <CyAppPublicLogo height="64"/>
      </div>
      <CyAlert
        theme="error"
        :content="allErrors"/>
      <p
        v-if="!hasErrors"
        class="login-info">
        {{ $t('loginInfo') }}
      </p>
      <v-progress-circular
        v-show="loading && !hasErrors"
        size="100"
        indeterminate
        color="secondary"
        class="loader"/>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import oidc from '@/utils/config/oidc'
import { getValidOrganization } from '@/utils/helpers'

export default {
  name: 'CySsoPopupCallback',
  data: () => ({
    loading: true,
    errors: null,
  }),
  computed: {
    ...mapState({
      organizations: (state) => state.organizations,
      appConfig: (state) => state.appConfig,
    }),
    ...mapState('auth', {
      socialProvider: (state) => state.socialProvider,
      ssoErrors: (state) => state.errors.sso,
    }),
    ...mapGetters([
      'hasOrgs',
    ]),
    ...mapGetters('auth', [
      'defaultRoute',
    ]),
    allErrors () {
      return [
        ...this.errors || [],
        ...this.ssoErrors || [],
      ]
    },
    hasErrors () {
      return !_.$isEmpty(this.allErrors)
    },
  },
  async created () {
    await this.GET_APP_CONFIG()

    const { appConfig: { authentication }, $route: { name: routeName } } = this

    if (!_.$isEmpty(authentication.saml2) && routeName === 'saml2Callback') await this.handleSAML2()
    if (!_.$isEmpty(authentication.oauth) && routeName === 'oidcPopupCallback') await this.handleOIDC()
    if (!_.$isEmpty(authentication.oauth) && routeName === 'githubCallback') await this.handleGitHub()

    this.loading = false
  },
  methods: {
    ...mapActions([
      'GET_APP_CONFIG',
    ]),
    ...mapActions('auth', [
      'HANDLE_SAML2_AUTH',
    ]),
    ...mapActions('organization', [
      'CHANGE_ORGANIZATION',
    ]),
    ...mapMutations('organization', [
      'SET_ORGANIZATION',
    ]),
    async handleOIDC () {
      oidc.setup(this.appConfig.authentication.oauth)

      try {
        await oidc.providers[this.socialProvider].signinPopupCallback()
      } catch (error) {
        const { name: code, message } = error
        this.errors = [{ code, message }]
      }
    },
    async handleSAML2 () {
      const { code: ssoCode } = this.$route.query

      if (!ssoCode) return

      await this.HANDLE_SAML2_AUTH({ ssoCode })
      if (_.$isEmpty(this.allErrors)) await this.redirectAfterLogin()
    },
    handleGitHub () {
      const { code: ssoCode } = this.$route.query
      ssoCode && localStorage.setItem(LSK.SSO_TOKEN_GITHUB, ssoCode)
      window.close()
    },
    async redirectAfterLogin () {
      this.hasOrgs ? await this.redirectToDefaultRoute() : this.redirectToOrgsList()
    },
    async redirectToDefaultRoute () {
      const nextCanonical = getValidOrganization(this.organizations).canonical

      if (_.$isEmpty(nextCanonical)) return

      await this.CHANGE_ORGANIZATION({ nextCanonical })
      this.$router.push(this.defaultRoute)
    },
    async redirectToOrgsList () {
      await this.SET_ORGANIZATION()
      this.$router.push({ name: 'organizations' })
    },
  },
  i18n: {
    messages: {
      en: {
        loginInfo: 'Awaiting response from authorization service...',
      },
      es: {
        loginInfo: 'Esperando respuesta del servicio de autorización...',
      },
      fr: {
        loginInfo: `En attente d'une réponse du service d'autorisation...`,
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.popup {
  display: flex;
  justify-content: center;
  height: 95%;
  margin: 16px;

  .v-card__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .login-info {
    margin-bottom: 32px;
    font-size: 1.2em;
  }
}
</style>
